import { Button } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import CountrySelector from 'views/CustomComponents/CountrySelector';

import { TextFormField } from '../CustomComponents/TextFormField';

const formObject = [
  {
    name: 'responsabilFinanciarDenumire',
    label: 'Responsabil Financiar Denumire',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'responsabilFinanciarAdresa',
    label: 'Responsabil Financiar Adresa',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'responsabilFinanciarTara',
    label: 'Responsabil Financiar Tara',
    disabled: false,
    placeholder: 'Responsabil Financiar Tara',
    component: CountrySelector,
  },
  {
    name: 'responsabilFinanciarCodPostal',
    label: 'Responsabil Financiar Cod Postal',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'responsabilFinanciarOras',
    label: 'Responsabil Financiar Oras',
    disabled: false,
    component: TextFormField,
  },
  {
    name: 'responsabilFinanciarCUI',
    label: 'Responsabil Financiar CUI',
    disabled: false,
    component: TextFormField,
  },
];

export default function ThirdPage(props) {
  const handleSubmit = (val) => {
    props.data['responsabilFinanciarDenumire'] =
      val.responsabilFinanciarDenumire;
    props.data['responsabilFinanciarAdresa'] = val.responsabilFinanciarAdresa;
    props.data['responsabilFinanciarTara'] = val.responsabilFinanciarTara;
    props.data['responsabilFinanciarCodPostal'] =
      val.responsabilFinanciarCodPostal;
    props.data['responsabilFinanciarOras'] = val.responsabilFinanciarOras;
    props.data['responsabilFinanciarCUI'] = val.responsabilFinanciarCUI;

    props.handleNext();
  };

  return (
    <div>
      <Formik
        initialValues={{
          responsabilFinanciarDenumire:
            props.data['responsabilFinanciarDenumire'],
          responsabilFinanciarAdresa: props.data['responsabilFinanciarAdresa'],
          responsabilFinanciarTara: props.data['responsabilFinanciarTara'],
          responsabilFinanciarCodPostal:
            props.data['responsabilFinanciarCodPostal'],
          responsabilFinanciarOras: props.data['responsabilFinanciarOras'],
          responsabilFinanciarCUI: props.data['responsabilFinanciarCUI'],
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {() => (
          <div style={{ marginTop: 60 }}>
            <Form>
              {formObject.map((snapshot, key) => {
                return <Field {...snapshot} key={key} />;
              })}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignSelf: 'center',
                  alignItems: 'center',
                  marginTop: 60,
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    width: '49%',
                    padding: 10,
                    color: 'grey',
                    fontWeight: '700',
                  }}
                  onClick={() => props.handleBack()}
                >
                  BACK
                </Button>
                <Button
                  variant="contained"
                  style={{
                    width: '49%',
                    padding: 10,
                    backgroundColor: 'rgb(0, 153, 159)',
                    color: 'white',
                    fontWeight: '700',
                  }}
                  type="submit"
                >
                  NEXT
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
