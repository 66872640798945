import { config, authHeaders } from './config';

export function getCurrentUser() {
  const userURL = `${config.apiUrl}/users/me`;

  let posting = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status == 401) {
      window.location.href = '/auth/login';
    } else {
      return a.json();
    }
  });

  let completeData = posting.then((a) => {
    return a;
  });

  return completeData;
}

export function postTemplate(body) {
  const URL = config.apiUrl + '/templates';

  let posting = fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('authToken'),
    },
    body: JSON.stringify(body),
  });

  return posting;
}

export function getTemplates(userId) {
  const userURL = `${config.apiUrl}/templates?userId.id=${userId}`;

  let posting = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status == 401) {
      window.location.href = '/auth/login';
    } else {
      return a.json();
    }
  });

  let completeData = posting.then((a) => {
    return a;
  });

  return completeData;
}

export function getTemplatesByID(id) {
  const userURL = `${config.apiUrl}/templates/${id}`;

  let posting = fetch(userURL, {
    method: 'GET',
    headers: authHeaders(),
  }).then((a) => {
    if (a.status == 401) {
      window.location.href = '/auth/login';
    } else {
      return a.json();
    }
  });

  let completeData = posting.then((a) => {
    return a;
  });

  return completeData;
}
