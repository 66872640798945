import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import MailOutline from '@material-ui/icons/MailOutline';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { DropzoneArea } from 'material-ui-dropzone';
import React from 'react';
import Datetime from 'react-datetime';
import { Table } from 'antd';
import {
  replaceDeclarationCSV,
  startRobot,
} from '../../services/declarationService.js';
import { getDeclaration } from 'services/declarationTable.js';
import { itemRender } from '../../paginationfunction';
import 'antd/dist/antd.css';
import moment from 'moment';
import { config } from 'services/config.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(styles);

export default function ListDeclaration() {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [activeId, setActiveId] = React.useState();
  const [date, setDate] = React.useState();
  const [files, setFiles] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState('');
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const classes = useStyles();

  const columns = [
    {
      title: 'ID',
      render: (text) => <p>{text['id']}</p>,
    },
    {
      title: 'LRN',
      render: (text) => <p>{text['lrn']}</p>,
    },
    {
      title: 'DATE',
      render: (text) => {
        return (
          <p>
            {moment(text['date'], 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY')}
          </p>
        );
      },
    },
    {
      title: 'STATUS',
      render: (text) => {
        if (text.declarationQueues[text.declarationQueues.length - 1]) {
          if (
            text.declarationQueues[text.declarationQueues.length - 1][
              'status'
            ] === 'done'
          ) {
            return (
              <p
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: 0.75,
                  color: '#35bd5b',
                }}
              >
                {
                  text.declarationQueues[text.declarationQueues.length - 1][
                    'status'
                  ]
                }
              </p>
            );
          } else if (
            text.declarationQueues[text.declarationQueues.length - 1][
              'status'
            ] === 'in progress'
          ) {
            return (
              <p
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: 0.75,
                  color: 'rgb(0,162,183)',
                }}
              >
                {
                  text.declarationQueues[text.declarationQueues.length - 1][
                    'status'
                  ]
                }
              </p>
            );
          } else if (
            text.declarationQueues[text.declarationQueues.length - 1][
              'status'
            ] === 'error'
          ) {
            return (
              <p
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: 0.75,
                  color: '#c94951',
                }}
              >
                {
                  text.declarationQueues[text.declarationQueues.length - 1][
                    'status'
                  ]
                }
              </p>
            );
          } else {
            return (
              <p
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: 0.75,
                  color: 'orange',
                }}
              >
                {
                  text.declarationQueues[text.declarationQueues.length - 1][
                    'status'
                  ]
                }
              </p>
            );
          }
        } else {
          return (
            <p
              style={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                letterSpacing: 0.75,
                color: '#c94951',
              }}
            >
              not ready
            </p>
          );
        }
      },
    },
    {
      title: 'ACTION',
      render: (text) => (
        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
          <div
            style={{
              backgroundColor: 'rgb(0,162,183)',
              display: 'inline-flex',
              alignItems: 'center',
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 9,
              paddingBottom: 9,
              borderRadius: 4,
              cursor: 'pointer',
            }}
            onClick={() => handleClickOpen(text.id)}
          >
            <span
              style={{
                color: 'white',
                fontWeight: 'bold',
                letterSpacing: 0.75,
              }}
            >
              CHANGE CSV
            </span>
          </div>
          <div
            style={{
              backgroundColor: 'orange',
              display: 'inline-flex',
              alignItems: 'center',
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 9,
              paddingBottom: 9,
              borderRadius: 4,
              cursor: 'pointer',
              marginTop: 5,
              marginBottom: 5,
            }}
            onClick={() => downloadCsv(text.filePath)}
          >
            <span
              style={{
                color: 'white',
                fontWeight: 'bold',
                letterSpacing: 0.75,
              }}
            >
              DONWLOAD CSV
            </span>
          </div>
          <div
            style={{
              backgroundColor: '#35bd5b',
              display: 'inline-flex',
              alignItems: 'center',
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 9,
              paddingBottom: 9,
              borderRadius: 4,
              cursor: 'pointer',
            }}
            onClick={() => startRobotF(text.id)}
          >
            <span
              style={{
                color: 'white',
                fontWeight: 'bold',
                letterSpacing: 0.75,
              }}
            >
              START ROBOT
            </span>
          </div>
        </div>
      ),
    },
  ];

  const handleUpload = (files) => {
    setFiles(files);
  };

  const handleDate = (newDate) => {
    setDate(newDate);
  };

  const handleSubmit = (val) => {
    if (!files[0]) {
      setError(true);
      setErrorDescription('Please upload CSV!');
      setTimeout(() => {
        setError(false);
        setErrorDescription('');
      }, [3000]);
    } else if (!date) {
      setError(true);
      setErrorDescription('Please enter invoice date!');
      setTimeout(() => {
        setError(false);
        setErrorDescription('');
      }, [3000]);
    } else {
      setButtonLoading(true);
      replaceDeclarationCSV(files[0], date, activeId).then((snapshot) => {
        if (snapshot.status < 300) {
          snapshot.json().then((response) => {
            handleClose();
            setCount((count) => count + 1);
            setButtonLoading(false);
          });
        } else {
          setError(true);
          setButtonLoading(false);
          setErrorDescription(
            'Something went wrong with your CSV, please try again!'
          );
          setTimeout(() => {
            setError(false);
            setErrorDescription('');
          }, [3000]);
        }
      });
    }
  };

  function downloadCsv(url) {
    const customUrl = config.apiUrl + url;
    window.open(customUrl, 'Download');
  }

  const handleClickOpen = (id) => {
    setActiveId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setActiveId();
    setOpen(false);
  };

  function startRobotF(id) {
    startRobot(id).then((response) => {
      if (response.status < 300) {
        setLoading(true);
        setCount((count) => count + 1);
      } else {
        alert('error');
      }
    });
  }

  React.useEffect(() => {
    setLoading(true);
    getDeclaration(1).then((snapshot) => {
      const page = Math.ceil(snapshot['hydra:totalItems'] / 30);
      setPageCount(page);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getDeclaration(page).then((data) => {
      setLoading(false);
      setData(data['hydra:member']);
    });
  }, [page, count]);

  React.useEffect(() => {
    setLoading(true);
    let x = setInterval(() => {
      getDeclaration(page).then((data) => {
        setData(data['hydra:member']);
        setLoading(false);
      });
    }, 30000);

    return () => clearInterval(x);
  }, [page, count]);

  return (
    <GridContainer justify="center">
      <Card>
        <Table
          className="table-striped"
          style={{ overflowX: 'auto' }}
          columns={columns}
          loading={loading}
          bordered
          rowKey={(snap) => {
            return snap.id;
          }}
          dataSource={data}
          showSizeChanger={false}
          pagination={{
            total: 30 * pageCount,
            itemRender: itemRender,
            pageSize: 30,
            onChange: (page) => setPage(page),
          }}
        />
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change CSV</DialogTitle>
        <DialogContent>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Upload new CSV</h4>
            </CardHeader>
            <CardBody>
              <form>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    onChange={handleDate}
                    closeOnSelect
                    dateFormat="DD.MM.YYYY"
                    inputProps={{ placeholder: 'Data facturii' }}
                  />
                </FormControl>
                <DropzoneArea
                  className={classes.fileUpload}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  filesLimit={1}
                  previewGridProps={{
                    container: { spacing: 1, direction: 'row' },
                  }}
                  previewText="Selected files"
                  onChange={handleUpload}
                />
              </form>
            </CardBody>
          </Card>
          {error ? <p style={{ color: 'red' }}>{errorDescription}</p> : null}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={handleSubmit}
              color="primary"
              disabled={buttonLoading}
            >
              Change CSV
            </Button>
            {buttonLoading && <CircularProgress size={24} />}
          </div>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}
