import { Button } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import Card from 'components/Card/Card.js';
import * as yup from 'yup';
import { TextField } from '@material-ui/core';

import { TextFormField } from '../CustomComponents/TextFormField';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';

export default function FinishPage(props) {
  const [stringify, setStringify] = React.useState('');

  React.useEffect(() => {
    const jsonS = JSON.stringify(props.data);
    const newStr = jsonS.replace(/,/g, '\n');
    setStringify(newStr);
  }, []);

  return (
    <div>
      <div style={{ marginTop: 30, width: 400 }}>
        <p>{stringify}</p>
      </div>

      <div style={{ marginTop: 15 }}>
        <Button
          variant="contained"
          style={{
            width: '25%',
            padding: 10,
            color: 'grey',
            fontWeight: '700',
          }}
          onClick={() => props.handleReset()}
        >
          RESET
        </Button>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignSelf: 'center',
          alignItems: 'center',
          marginTop: 60,
        }}
      >
        <Button
          variant="contained"
          style={{
            width: '49%',
            padding: 10,
            color: 'grey',
            fontWeight: '700',
          }}
          onClick={() => props.handleBack()}
        >
          BACK
        </Button>
        <Button
          variant="contained"
          style={{
            width: '49%',
            padding: 10,
            backgroundColor: 'rgb(0, 153, 159)',
            color: 'white',
            fontWeight: '700',
          }}
          onClick={() => props.handleSubmit()}
        >
          SUBMIT NEW TEMPLATE
        </Button>
      </div>
    </div>
  );
}
