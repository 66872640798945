import { makeStyles } from '@material-ui/core/styles';
import MailOutline from '@material-ui/icons/MailOutline';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import Card from 'components/Card/Card.js';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import { DropzoneArea } from 'material-ui-dropzone';
import React from 'react';
import { Table } from 'antd';
import ButtonAntd from 'antd/es/button';
import { getReportingDeclaration } from 'services/reportDeclarationTable.js';
import { itemRender } from '../../paginationfunction';
import 'antd/dist/antd.css';
import moment from 'moment';
import { config } from 'services/config.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
import { addReportinDeclaration } from 'services/reportDeclarationTable.js';
import { startReportingRobot } from 'services/reportDeclarationTable.js';
import { generate_csv } from 'services/reportDeclarationTable.js';
import { updateCSVReportinDeclaration } from 'services/reportDeclarationTable.js';
import Select from 'react-select';
import { addGeneratedReportingDeclaration } from 'services/reportDeclarationTable';
import { deleteReportingDeclarationService } from 'services/reportDeclarationTable';

const useStyles = makeStyles(styles);

export default function ReportingListDeclaration() {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [lrn, setLrn] = React.useState('');
  const [activeId, setActiveId] = React.useState();
  const [date, setDate] = React.useState();
  const [files, setFiles] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState('');
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [errorText, setErrorText] = React.useState();
  const [dateListFilter, setDateListFilter] = React.useState([{}]);
  const classes = useStyles();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [loadingRows, setLoadingRows] = React.useState(false);
  const [selectedRowAllData, setSelectedRowAllData] = React.useState([]);
  const [submitButton, setSubmitButton] = React.useState(false);
  const [raportButton, setRaportButton] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState(null);

  const columns = [
    {
      title: 'ID',
      render: (text) => <p>{text['id']}</p>,
    },
    {
      title: 'LRN',
      render: (text) => <p>{text['lrn']}</p>,
    },
    {
      title: 'DATE',
      render: (text) => {
        return <p>{text['date']}</p>;
      },
    },
    {
      title: 'STATUS',
      render: (text) => {
        if (text.declarationQueues[text.declarationQueues.length - 1]) {
          if (
            text.declarationQueues[text.declarationQueues.length - 1][
              'status'
            ] === 'done'
          ) {
            return (
              <p
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: 0.75,
                  color: '#35bd5b',
                }}
              >
                {'Date din dvi preluate cu succes'}
              </p>
            );
          } else if (
            text.declarationQueues[text.declarationQueues.length - 1][
              'status'
            ] === 'in progress'
          ) {
            return (
              <p
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: 0.75,
                  color: 'rgb(0,162,183)',
                }}
              >
                {
                  text.declarationQueues[text.declarationQueues.length - 1][
                    'status'
                  ]
                }
              </p>
            );
          } else if (
            text.declarationQueues[text.declarationQueues.length - 1][
              'status'
            ] === 'error'
          ) {
            return (
              <p
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: 0.75,
                  color: '#c94951',
                }}
              >
                {
                  text.declarationQueues[text.declarationQueues.length - 1][
                    'status'
                  ]
                }
              </p>
            );
          } else {
            return (
              <p
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: 0.75,
                  color: 'orange',
                }}
              >
                {
                  text.declarationQueues[text.declarationQueues.length - 1][
                    'status'
                  ]
                }
              </p>
            );
          }
        } else {
          return (
            <p
              style={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                letterSpacing: 0.75,
                color: '#c94951',
              }}
            >
              not ready
            </p>
          );
        }
      },
    },
    {
      title: 'ACTION',
      render: (text) => {
        if (text.declarationQueues[text.declarationQueues.length - 1]) {
          return (
            <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
              {text.declarationQueues[text.declarationQueues.length - 1][
                'status'
              ] == 'done' ? (
                <div
                  style={{ display: 'inline-flex', flexDirection: 'column' }}
                >
                  <div
                    style={{
                      backgroundColor: 'orange',
                      display: 'inline-flex',
                      alignItems: 'center',
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 9,
                      paddingBottom: 9,
                      borderRadius: 4,
                      cursor: 'pointer',
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                    onClick={() => !raportButton && downloadCsv(text.lrn)}
                  >
                    <span
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        letterSpacing: 0.75,
                      }}
                    >
                      {raportButton ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        'GENERARE RAPORT'
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      backgroundColor: 'rgb(0,162,183)',
                      display: 'inline-flex',
                      alignItems: 'center',
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 9,
                      paddingBottom: 9,
                      borderRadius: 4,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleClickOpen(text.id)}
                  >
                    <span
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        letterSpacing: 0.75,
                      }}
                    >
                      SCHIMBA FACTURA
                    </span>
                  </div>

                  <div
                    style={{
                      backgroundColor: '#ba1407',
                      display: 'inline-flex',
                      alignItems: 'center',
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 9,
                      paddingBottom: 9,
                      borderRadius: 4,
                      cursor: 'pointer',
                      marginTop: 5,
                    }}
                    onClick={() => deleteReportingDeclaration(text.id)}
                  >
                    <span
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        letterSpacing: 0.75,
                      }}
                    >
                      STERGE
                    </span>
                  </div>
                </div>
              ) : null}
              {text.declarationQueues[text.declarationQueues.length - 1][
                'status'
              ] === 'error' ? (
                <div
                  style={{ display: 'inline-flex', flexDirection: 'column' }}
                >
                  <div
                    style={{
                      backgroundColor: '#35bd5b',
                      display: 'inline-flex',
                      alignItems: 'center',
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 9,
                      paddingBottom: 9,
                      borderRadius: 4,
                      cursor: 'pointer',
                    }}
                    onClick={() => startRobotF(text.id)}
                  >
                    <span
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        letterSpacing: 0.75,
                      }}
                    >
                      START ROBOT
                    </span>
                  </div>
                  <div
                    style={{
                      backgroundColor: '#ba1407',
                      display: 'inline-flex',
                      alignItems: 'center',
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 9,
                      paddingBottom: 9,
                      borderRadius: 4,
                      cursor: 'pointer',
                      marginTop: 5,
                    }}
                    onClick={() => deleteReportingDeclaration(text.id)}
                  >
                    <span
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        letterSpacing: 0.75,
                      }}
                    >
                      STERGE
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          );
        }
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled:
        record.declarationQueues[record.declarationQueues.length - 1] &&
        record.declarationQueues[record.declarationQueues.length - 1][
          'status'
        ] !== 'done',
      // Column configuration not to be checked
      name: record.lrn,
    }),
  };
  const hasSelected = selectedRowKeys.length > 0;

  function start() {
    setLoadingRows(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setSelectedRowAllData([]);
      setLoadingRows(false);
    }, 1000);
  }

  async function deleteReportingDeclaration(reportingDeclarationId) {
    const resp = await deleteReportingDeclarationService(
      reportingDeclarationId
    );

    if (resp.status > 299) {
      alert('A intervenit o eroare la stergerea declaratiei!');
    } else {
      setCount((count) => count + 1);
    }
  }

  async function multipleLRNRaport() {
    setRaportButton(true);
    const listLrn = selectedRowAllData.map((val) => {
      return val.lrn;
    });
    const csv = await generate_csv(listLrn, null);
    const read_csv = await csv.json();
    handleErrorGenerareCSV(read_csv);
  }

  function onSelectChange(selectedRowKeys, selectedRows) {
    setSelectedRowAllData(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  }

  const handleUpload = (files) => {
    setFiles(files[0]);
  };

  async function handleErrorGenerareCSV(read_csv, per_day_interogation) {
    if (read_csv.status === 'OK') {
      if (read_csv.report_csv_url) {
        const customUrl = config.apiUrl + read_csv.report_csv_url;
        window.open(customUrl, 'Download');

        if (per_day_interogation) {
          const resp = await addGeneratedReportingDeclaration(
            selectedDay.value,
            read_csv.report_csv_url
          );
          const resp_json = await resp.json();
        }

        setRaportButton(false);
      } else {
        alert('Eroare la generat raport. Va rugam contactati echipa tehnica!');
        setRaportButton(false);
      }
    } else {
      if (read_csv.detail) {
        alert(read_csv.detail);
        setRaportButton(false);
      } else {
        alert('Eroare la generat raport. Va rugam contactati echipa tehnica!');
        setRaportButton(false);
      }
    }
  }

  const handleClickOpen = (id) => {
    setActiveId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setActiveId();
    setOpen(false);
  };

  async function downloadCsv(lrn) {
    setRaportButton(true);
    let singleLrnInList = [lrn];
    const csv = await generate_csv(singleLrnInList, null);
    const read_csv = await csv.json();
    handleErrorGenerareCSV(read_csv);
  }

  function startRobotF(id) {
    startReportingRobot(id);
    setCount((count) => count + 1);
  }

  async function addReportingDeclarationFunction() {
    setSubmitButton(true);
    if (lrn.length < 3) {
      setErrorText('LRN este invalid');
      setTimeout(() => {
        setErrorText();
      }, 3000);
      setSubmitButton(false);
    } else if (!files) {
      setErrorText('XLSX Invalid');
      setTimeout(() => {
        setErrorText();
      }, 3000);
      setSubmitButton(false);
    } else {
      const now = moment();
      const date = moment(now).format('DD MMM YYYY');
      const response = await addReportinDeclaration(lrn, date, files);
      if (response.status < 300) {
        const response_json = await response.json();

        startReportingRobot(response_json['id']);
        setLrn('');
        setFiles('');
        setCount((count) => count + 1);
        setSubmitButton(false);
      } else {
        const response_json = await response.json();

        if (response_json['hydra:description']) {
          setErrorText(response_json['hydra:description']);
          setTimeout(() => {
            setErrorText();
          }, 3000);
          setSubmitButton(false);
        } else {
          setErrorText('A aparut o eroare. Incearca alt LRN!');
          setTimeout(() => {
            setErrorText();
          }, 3000);
          setSubmitButton(false);
        }
      }
    }
  }

  async function changeFacturaReportingDeclaration() {
    if (!files) {
      setError(true);
      setErrorDescription('Please upload XLSX!');
      setTimeout(() => {
        setError(false);
        setErrorDescription('');
      }, [3000]);
    } else {
      setButtonLoading(true);
      const request = await updateCSVReportinDeclaration(files, activeId);
      const response = await request.json();
      if (response.status == 'OK') {
        handleClose();
        setCount((count) => count + 1);
        setButtonLoading(false);
      } else {
        if (response.detail) {
          setError(true);
          setButtonLoading(false);
          setErrorDescription(response.detail);
          setTimeout(() => {
            setError(false);
            setErrorDescription('');
          }, [3000]);
        } else {
          setError(true);
          setButtonLoading(false);
          setErrorDescription(
            'Something went wrong with your XLSX, please try again!'
          );
          setTimeout(() => {
            setError(false);
            setErrorDescription('');
          }, [3000]);
        }
      }
    }
  }

  React.useEffect(() => {
    const today = moment();
    let dateList = [];
    for (let i = 0; i < 15; i++) {
      const formatedDate = moment(today)
        .subtract(i, 'days')
        .format('DD MMM YYYY');
      dateList.push({ value: formatedDate, label: formatedDate });
    }
    setDateListFilter(dateList);

    setLoading(true);
    getReportingDeclaration(1).then((snapshot) => {
      const page = Math.ceil(snapshot['hydra:totalItems'] / 30);
      setPageCount(page);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getReportingDeclaration(page).then((data) => {
      setData(data['hydra:member']);
      setLoading(false);
    });
  }, [page, count]);

  React.useEffect(() => {
    setLoading(true);
    let x = setInterval(() => {
      getReportingDeclaration(page).then((data) => {
        setData(data['hydra:member']);
        setLoading(false);
      });
    }, 30000);

    return () => clearInterval(x);
  }, [page, count]);

  async function submitRaportPerDay() {
    setRaportButton(true);
    const csv = await generate_csv(null, selectedDay.value);
    const read_csv = await csv.json();
    handleErrorGenerareCSV(read_csv, true);
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '49%' }}>
          <TextField
            style={{ width: '100%' }}
            id="outlined-basic"
            variant="outlined"
            placeholder="LRN"
            value={lrn}
            onChange={(val) => setLrn(val.target.value)}
          />
          <Card style={{ marginTop: 40, width: '100%' }}>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Upload XLSX Factura</h4>
            </CardHeader>
            <CardBody>
              <form>
                <DropzoneArea
                  className={classes.fileUpload}
                  showPreviews={true}
                  //acceptedFiles={['text/csv']}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  filesLimit={1}
                  previewGridProps={{
                    container: { spacing: 1, direction: 'row' },
                  }}
                  previewText="Selected files"
                  onChange={handleUpload}
                />
              </form>
            </CardBody>
          </Card>
          <div>
            {!!errorText && <p style={{ color: 'red' }}>{errorText}</p>}
          </div>
          <Button
            variant="contained"
            style={{
              width: '100%',
              backgroundColor: 'rgb(0, 153, 159)',
              color: 'white',
              fontWeight: '700',
              marginLeft: 30,
              borderRadius: 4,
              height: 56,
              marginTop: 0,
              marginLeft: 0,
            }}
            disabled={submitButton}
            onClick={addReportingDeclarationFunction}
          >
            {submitButton ? (
              <CircularProgress color="#fff" size={24} />
            ) : (
              'INCEPE PROCES'
            )}
          </Button>
        </div>

        <div
          style={{
            width: '49%',
            alignSelf: 'flex-end',
          }}
        >
          <div style={{ width: '100%' }}>
            <Select
              placeholder={'Selecteaza o zi'}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                }),
                control: (provided) => ({
                  ...provided,
                  height: 56,
                  backgroundColor: '#eeeeee',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: 'rgb(102,102,102)',
                },
              })}
              options={dateListFilter}
              value={selectedDay}
              onChange={(option) => setSelectedDay(option)}
              menuPortalTarget={document.querySelector('body')}
            />
          </div>
          <Button
            variant="contained"
            style={{
              width: '100%',
              backgroundColor: 'rgb(0, 153, 159)',
              color: 'white',
              fontWeight: '700',
              borderRadius: 4,
              height: 56,
              marginTop: 30,
            }}
            disabled={raportButton}
            onClick={submitRaportPerDay}
          >
            {raportButton ? (
              <CircularProgress color="#fff" size={24} />
            ) : (
              'GENEREAZA RAPORT'
            )}
          </Button>
        </div>
      </div>
      <GridContainer justify="center">
        <Card style={{ marginTop: 30 }}>
          <div>
            <ButtonAntd
              type="primary"
              onClick={start}
              disabled={!hasSelected}
              loading={loadingRows}
            >
              Reload
            </ButtonAntd>

            <span style={{ marginLeft: 8 }}>
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
            </span>

            <ButtonAntd
              type="primary"
              onClick={multipleLRNRaport}
              disabled={selectedRowAllData.length > 0 ? false : true}
              style={{ marginLeft: 20 }}
            >
              Generare report
            </ButtonAntd>
          </div>
          <Table
            rowSelection={rowSelection}
            className="table-striped"
            style={{ overflowX: 'auto' }}
            columns={columns}
            loading={loading}
            bordered
            rowKey={(snap) => {
              return snap.id;
            }}
            dataSource={data}
            showSizeChanger={false}
            pagination={{
              total: 30 * pageCount,
              itemRender: itemRender,
              pageSize: 30,
              onChange: (page) => setPage(page),
            }}
          />
        </Card>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Schimba factura</DialogTitle>
          <DialogContent>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <MailOutline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Incarca factura noua</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <DropzoneArea
                    className={classes.fileUpload}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview
                    filesLimit={1}
                    previewGridProps={{
                      container: { spacing: 1, direction: 'row' },
                    }}
                    previewText="Selected files"
                    onChange={handleUpload}
                  />
                </form>
              </CardBody>
            </Card>
            {error ? <p style={{ color: 'red' }}>{errorDescription}</p> : null}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={changeFacturaReportingDeclaration}
                color="primary"
                disabled={buttonLoading}
              >
                Schimba factura
              </Button>
              {buttonLoading && <CircularProgress size={24} />}
            </div>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </div>
  );
}
