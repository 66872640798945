import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

function CountrySelector({ form, field, options, placeholder }) {
  const optionsCountry = useMemo(() => {
    const codes = countryList().getValues();
    const countryCodes = codes.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    return countryCodes;
  }, []);

  return (
    <div
      style={{
        width: '100%',
        marginTop: 30,
        overflow: 'visible',
        zIndex: 1,
      }}
    >
      <Select
        placeholder={placeholder}
        styles={{
          option: (provided, state) => ({
            ...provided,
          }),
          control: (provided) => ({
            ...provided,
            height: 56,
            backgroundColor: '#eeeeee',
            border: '1px solid rgba(0, 0, 0, 0.23)',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: 'rgb(102,102,102)',
          },
        })}
        options={optionsCountry}
        value={field.value.label ? field.value : field.value.label}
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        menuPortalTarget={document.querySelector('body')}
      />
    </div>
  );
}

export default CountrySelector;
