import React, { useState } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';
import loginUser from '../../services/login';
import jwt_decode from 'jwt-decode';
import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
import routes from 'routes.js';
const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const [errorText, setErrorText] = useState('');
  const isFormValid = () =>
    user.email.length > 3 && user.password.trim() !== '';

  function handleInputChange(e) {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  }

  function signUserIn(e) {
    e.preventDefault();
    if (isFormValid) {
      loginUser(user.email, user.password).then((res) => {
        if (!res.token) {
          setErrorText('There was an error, please try again.');
          setTimeout(() => {
            setErrorText('');
          }, 3000);
          return;
        } else {
          localStorage.setItem('authToken', res['token']);
          let decoded = jwt_decode(res['token']);

          localStorage.setItem('user', decoded.username);
          localStorage.setItem('roles', decoded.roles);

          if (decoded.roles.includes('ROLE_DECLARATION')) {
            props.history.push('/admin/listDeclaration', { user: user });
          } else if (decoded.roles.includes('ROLE_REPORTING')) {
            props.history.push('/admin/listReporting', { user: user });
          } else {
            setErrorText('Nu ai rol asignat pentru a accesa platforma!');
            setTimeout(() => {
              setErrorText('');
            }, 3000);
            localStorage.removeItem('authToken');
            localStorage.removeItem('user');
            localStorage.removeItem('roles');
          }
        }
      });
    } else {
      setErrorText('Please enter a valid email and password');
      setTimeout(() => {
        setErrorText('');
      }, 3000);
    }
  }

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation('');
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: 'email',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onChange: (e) => handleInputChange(e),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: 'password',
                    onChange: (e) => handleInputChange(e),

                    autoComplete: 'off',
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color="rose"
                  simple
                  size="lg"
                  onClick={(e) => signUserIn(e)}
                  block
                >
                  Log In
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
